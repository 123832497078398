.App {
  margin: 0px;
  padding: 0px;
}

.App .navbar-brand {
  font-weight: bold;
}

.AppContainer{
  height: 100% !important;
  
}