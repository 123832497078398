.DeleteButton{
    margin: 0px!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    height: 45px!important;
}

.error-message{
    padding-left: 10px;
    padding-right: 10px;
    padding-top:10px;
    margin-left: 10px;
    height: 47px;
    font-weight: 500;
    border-radius: 10px;
    color: #ff3547;
}