

.text-center font-weight-bold pt-4 pb-5 mb-2{

}

.address-errors{
    color:red;
}

.OrderSum{
    text-align: center;
}