.AddButton {
    margin: 0px!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    height: 45px!important;
    color: #fff !important;
    text-align: center;
}

.NavText{
    padding-top:14px!important;
}

.PageView{
    float: right;
    margin-left: 20px;
}

.ReturnButton {
    margin: 0px!important;
    height: 45px!important;
    color: #fff !important;
    text-align: center;
}

.buttonIcon{
    margin-right:5px;
}