.ExportButton {
    margin: 0px!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    height: 45px!important;
    color: #fff !important;
    text-align: center;
}

.NavText{
    padding-top:14px!important;
}
