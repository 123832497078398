.PagInfo {
    text-align: center;
    padding: 5px;
}

.Pages {
    text-align: center;
    padding: 5px;
}

.NavButtons {
    text-align: center;
    padding: 5px;
}

th {
    color: white!important;
}

select {
    color: black;
}

input {
    color: black;
}

.Filters {
    color: blue;
}

.AddNewUserButton {
    margin: 0px!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    height: 45px!important;
    color: #fff !important;
    text-align: center;
}

.NavText{
    padding-top:14px!important;
}