.PagInfo {
    text-align: center;
    padding: 5px;
}

.Pages {
    text-align: center;
    padding: 5px;
}

.NavButtons {
    text-align: center;
    padding: 5px;
}

thead {
    background-color: #2196f3;
}

th {
    color: black!important;
}

select {
    color: black;
}

input {
    color: black;
}

.Filters {
    color: blue;
}

.Manage {
    border: 0.5px solid #dee2e6;
    margin-bottom: 20px;
}

.NewOrderButton {
    margin: 0px!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    height: 45px!important;
    color: #fff !important;
    text-align: center;
}

.NavText {
    padding-top: 14px!important;
}