.conf-check{
    text-align: center;
    width: 50vw;
    margin: auto;
    max-width: 25em;
    height: 250px;
    max-height:400px;
  
    position: relative;
    padding: 25px;
    box-sizing: border-box;
  
    $border: 5px;
    color: #000;
    background: #FFF;
    background-clip: padding-box; 
    border: solid $border transparent; 
    border-radius: 1em;
  
    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -$border; 
      border-radius: inherit; 
      background: linear-gradient(to right, #2bbbad, #7ed5f5);
    }
  }