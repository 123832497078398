.ScrollButton {
    margin: 0px!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    height: 45px!important;
    color: #fff !important;
    text-align: center;
}

.SmoothScroll{
    padding-top:0px!important;
    padding-left:4px!important;
    padding-right: 4px!important;
}