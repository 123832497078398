/* body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
} */

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

input[type=file] {
  width: 100%;
}

.input-error {
  padding: 5px;
  color: red;
  font-size: 12px;
}

.input-error:before {
  content: '*';
}

.small {
  font-size: 12px;
}

.medium {
  font-size: 14px;
}

@media all and (min-width: 480px) {
  .simple-form {
        padding: 60px 0;
  }

  .simple-form form {
        margin: 0 auto;
        max-width: 320px;
  }
}