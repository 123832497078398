.downloadLinks{
    text-align: right;
}

.api{
    padding-right: 52px;
    text-align: right;
}

.apiKey{
    color: #2bbbad;
    font-weight: 700;
}