.Header {
    
}

.NavItem{
    padding: 7.5px;
    color: #000;
}

.MDBContainer{
    padding-right: 15px;
    padding-left: 15px;

}

.View{
    width:100%;
}

.Intro{
    padding-left: 0px;
    padding-right: 0px;
}

button.navbar-toggler{
    background-color:#2bbbad!important;
    color:#fff;
}

.NavDropDown.item{
    color:#000!important;
}

.button{
    padding:0;
    margin:0;
}

.userHeader{
    justify-content: right!important;
    padding-left: 100px!important;
    right:0px;
    position: absolute;
}

.nav-link.active{
    background-color: #5399b1;
    color:#fff!important;
}

.dropdown-item.active{
    background-color: #5399b1!important;
    color:#fff;
}

.dropdown-item:hover{
    background-color: #bbeeff!important;
    color:#000!important;
}

.navbar-dark .navbar-nav .nav-link{
    color:#000!important;
}

.user-icon{
    padding-top: 9px;
}