.code {
    background-color: #f5f5f5;
    padding: 3px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.code code {
    color: black;
}

.quote {
    margin-left: 10px;
    padding-left: 10px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    border-left: double grey;
    background-color: #f5f5f5;
}

.li {
    margin-top: 2px;
    margin-bottom: 2px;
}

#SureCanPay-Integration-Guide {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 2rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}

#Running-the-Driver {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}

#Getting-Started {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}

#testEPOS {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}

#swaggerUi {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}

#Windows {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}

#Android {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}

#Callbacks {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}

#Driver-Configuration {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}

#Integrating-the-Driver-into-your-Application {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}

#Transactions {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}

#Settlement-and-End-of-Day {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}

#Receipt-Formatting {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}

#Printing-Receipts {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}

#Firmware-Updates {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}

#Security {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}

#Error-Codes {
    background-color: #2bbbad;
    padding: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif!important;
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 2.5px 5px 9px #b6b6b6;
}