.AddButton{
  margin: 0px!important;
  padding-top: 0px!important;
  padding-bottom: 0px!important;
  height: 45px!important;
}

.RemoveButton{
  margin: 0px 5px 0 5px!important;
  padding-top: 0px!important;
  padding-bottom: 0px!important;
  height: 45px!important;
}

