.NotifyButton {
    margin: 0px!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    height: 45px!important;
    color: #fff !important;
    text-align: center;
}

.InfoButton {
    margin-top: 0px!important;
    margin-bottom: 0px!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    height: 45px!important;
    color: #fff !important;
    text-align: center;
}

.Text{
    padding-top:14px!important;
}

.buttonIcons{
    margin-right:3px;
    padding-right: 2px;
}