[type="checkbox"] {
    opacity: 1 !important;
}

.InputForm{
    display:grid;
}

.TextInput{
  padding:5px;
  margin:5px;
}

.checkboxes{
  margin-top:5px;
  margin-bottom:5px;
  margin-left: 0px;    
}

.TopSection{
  display: grid;
  padding: 5px;
  margin: 5px;
  width:100%;
  border: 0px!important;
}

.form-check{
  margin-left: 0px;
  padding-left: 5px!important;
}

